import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideAppInitializer,
} from '@angular/core';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideNoopAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { createErrorHandler } from '@sentry/angular';
import { registerables } from 'chart.js';
import { provideCharts } from 'ng2-charts';
import { LightboxModule } from 'ngx-lightbox';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { errorInterceptor } from './interceptors/error.interceptor';
import { authInterceptor } from './interceptors/jwt.interceptor';
import { ConfigurationService } from './services/configuration/configuration.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const initializerFn = (
        (configService: ConfigurationService) => () =>
          configService.loadConfigurations()
      )(inject(ConfigurationService));
      return initializerFn();
    }),

    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(ToastrModule.forRoot()),
    importProvidersFrom(LightboxModule),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules)),
    provideEnvironmentNgxMask(),
    provideCharts({
      registerables: registerables,
    }),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useValue: createErrorHandler({
              showDialog: true,
            }),
          },
        ]
      : []),
    provideNoopAnimations(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor, errorInterceptor])),
    provideClientHydration(withEventReplay()),
  ],
};
