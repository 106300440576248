import { Injectable, inject } from '@angular/core';
import { PermissionsService } from '../permissions/permissions.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private permissionService = inject(PermissionsService);
  private authService = inject(AuthService);

  public async loadConfigurations() {
    if (this.authService.isValid) {
      return await firstValueFrom(this.permissionService.getAll());
    }

    return Promise.resolve();
  }
}
