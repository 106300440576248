import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GenericSearch, Paginate } from '../../models/common';
import { User, UserInPaginate } from '../../models/user';
import { camelToSnakeCase } from '../../utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);

  getAllPaginate(page = 1, perPage = 15, search: GenericSearch | null = null) {
    let params = new HttpParams({
      fromObject: {
        page: page,
        per_page: perPage,
      },
    });

    if (search) {
      Object.entries(search)
        .filter(([, value]) => value != null && value != 'null')
        .forEach(([key, value]) => {
          params = params.append(camelToSnakeCase(key), String(value));
        });
    }

    return this.http.get<Paginate<UserInPaginate>>(`${environment.apiUrl}/users`, { params });
  }

  getAll() {
    return this.http.get<UserInPaginate[]>(`${environment.apiUrl}/users/all`);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user`);
  }

  getById(id: number): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
  }

  changePassword(user: User) {
    return this.http.put(`${environment.apiUrl}/users/change-password`, user);
  }

  registerAll(user: FormData) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    return this.http.post(`${environment.apiUrl}/user/save`, user, { headers });
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/user/${id}`);
  }

  autocomplete(search: string, companyId: number | null = null) {
    let params = new HttpParams().append('search', search);

    if (companyId) {
      params = params.append('company_id', companyId.toString());
    }

    return this.http.get<UserInPaginate[]>(`${environment.apiUrl}/users/autocomplete`, { params });
  }

  getByIdAutocomplete(id: number) {
    return this.http.get<UserInPaginate>(`${environment.apiUrl}/users/autocomplete/${id}`);
  }

  validateUserUniqueness(params: { email?: string; username?: string }, userId: number) {
    if (!params.username && !params.email) {
      return of(false);
    }

    return this.http.get<boolean>(`${environment.apiUrl}/users/validate-user/${userId}`, { params });
  }
}
