import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LocationService } from './services/location/location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, NgxSpinnerModule],
})
export class AppComponent implements OnInit {
  private geo = inject(LocationService);

  public async ngOnInit() {
    await this.geo.getLocation();
  }
}
