import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

export function errorInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  const jwtService = inject(AuthService);
  const toastService = inject(ToastrService);
  const router = inject(Router);

  return next(request).pipe(
    catchError(err => {
      if (router.url !== '/login' && (err.status === 401 || err.status === 403)) {
        // auto logout if [401, 403] response returned from api
        jwtService.logout();
        router.navigate(['/login']).then();

        toastService.error('A sua sessão terminou, por favor logue novamente!', 'Não autorizado');
      }

      return throwError(() => err);
    })
  );
}
