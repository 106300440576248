import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Login, ProfileSlug, Token, User } from '../../models/user';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private userService = inject(UserService);

  public readonly currentAccessToken: Observable<Token | null>;
  private readonly currentAccessTokenSubject: BehaviorSubject<Token | null>;

  constructor() {
    this.currentAccessTokenSubject = new BehaviorSubject<Token | null>(this._tokenFromLocalStorage());
    this.currentAccessToken = this.currentAccessTokenSubject.asObservable();
  }

  public get token(): Token | null {
    try {
      const token = this._tokenFromLocalStorage();
      this.currentAccessTokenSubject.next(token);

      return token;
    } catch (e) {
      return null;
    }
  }

  public set token(value: Token | null) {
    try {
      localStorage.setItem('currentUser', JSON.stringify(value));
      this.currentAccessTokenSubject.next(value);
    } catch (err) {
      console.error('Error setting token', err);
    }
  }

  get tokenType(): string | null {
    return this.token?.token_type ?? null;
  }

  get accessToken(): string | null {
    return this.token?.access_token ?? null;
  }

  get expiryTime(): number | null {
    return this.token?.expire ? Date.parse(this.token?.expire) : null;
  }

  public get userId(): number | null {
    try {
      if (!this.isValid) {
        return null;
      }

      const token = this._tokenFromLocalStorage();

      return token!.user?.id || null;
    } catch (e) {
      return null;
    }
  }

  public get profileId(): number | null {
    try {
      if (!this.isValid) {
        return null;
      }

      const token = this._tokenFromLocalStorage();

      return token!.user?.profile.id || token!.user?.profile_id || null;
    } catch (e) {
      return null;
    }
  }

  public get isValid(): boolean {
    try {
      const token = this._tokenFromLocalStorage();

      if (!token) {
        this.currentAccessTokenSubject.next(null);
        return false;
      }

      return new Date(Date.parse(token.expire)) > new Date();
    } catch (e) {
      return false;
    }
  }

  get isAdmin() {
    try {
      if (!this.isValid) {
        return false;
      }

      const token = this._tokenFromLocalStorage();

      return token!.user.profile.slug !== ProfileSlug.COLLABORATOR;
    } catch (e) {
      return false;
    }
  }

  get company(): number | null {
    try {
      if (!this.isValid) {
        return null;
      }

      const token = this._tokenFromLocalStorage();

      return token!.user.company_id || null;
    } catch (e) {
      return null;
    }
  }

  public revalidate() {
    this.currentAccessTokenSubject.next(this._tokenFromLocalStorage());
  }

  public login(login: Login): Observable<Token | null> {
    return this.http.post<Token>(`${environment.apiUrl}/token`, login).pipe(
      tap((token: Token) => {
        this.token = token;
      }),
      switchMap((auth: Token) => this.getUser(auth))
    );
  }

  public getUser(auth: Token) {
    return this.userService.getCurrentUser().pipe(
      map((user: User) => {
        auth.user = user;

        this.token = auth;

        return auth;
      })
    );
  }

  public logout() {
    console.debug('Logout start');

    console.debug('Removing localStorage items');
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('checkingFile');

    console.debug('Navigating to Login');

    this.router.navigate(['/', 'login']).then(console.debug);

    console.debug('Resetting header');
    this.currentAccessTokenSubject.next(null);

    console.debug('Logout end');
  }

  private _tokenFromLocalStorage(): Token | null {
    try {
      return JSON.parse(localStorage.getItem('currentUser') as string);
    } catch {
      return null;
    }
  }

  get isTokenExpired(): boolean {
    if (!this.token) {
      return true;
    }

    const expiryTime: number | null = this.expiryTime;
    return expiryTime ? expiryTime < new Date().getTime() : true;
  }
}
