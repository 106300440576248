import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { init } from '@sentry/angular';
import { environment } from './environments/environment';

if (environment.production) {
  init({
    dsn: 'https://733f4e3fbb9d9dda2c1c2ce3e8470243@o434849.ingest.sentry.io/4506469211897856',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

registerLocaleData(localePt);

bootstrapApplication(AppComponent, appConfig).then(() => {
  console.debug('Application started');
});
