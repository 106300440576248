import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

import { inject } from '@angular/core';

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  const jwtService = inject(AuthService);

  if (request.url.includes(environment.apiUrl)) {
    if (!jwtService.isTokenExpired) {
      request = request.clone({
        setHeaders: {
          Authorization: `${jwtService.tokenType} ${jwtService.accessToken}`,
        },
      });
    }
  }

  return next(request);
}
